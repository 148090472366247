/*custom-checkbox*/
.checkboxCustom {
    opacity: 0;
    position: absolute;
}
.checkboxCustom,
.checkboxCustomLabel {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
.checkboxCustomLabel,
.radio-custom-label {
    position: relative;
}
.checkboxCustom + .checkboxCustomLabel:before {
    content: "";
    background: #fff;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    line-height: 15px;
    padding: 2px;
    margin-right: 0;
    text-align: center;
}

.checkboxCustom:checked + .checkboxCustomLabel:before {
    content: "✓";
    font-family: "FontAwesome";
    background: green;
    color: var(--text-white);
}
.checkboxCustom:checked + .red.checkboxCustomLabel:before {
    background: red;
}
.checkboxCustom:checked + .yellow.checkboxCustomLabel:before {
    background: yellow;
    color: var(--text-black);
}
.checkboxCustom:checked + .blue.checkboxCustomLabel:before {
    background: #005CC8;
}
.checkboxCustom:checked + .orange.checkboxCustomLabel:before {
    background: red;
}
.checkbox-custom:focus + .checkboxCustomLabel {
    /*outline: 1px solid #ddd; !* focus style *!*/
}
/*radio*/
.radioButton {
    padding: 2px;
    margin-top: 10px;
    margin-right: 5px;
}