.checkBoxButton {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
}

.checkBoxButton label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.checkBoxButton input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.checkBoxButton input[type=checkbox]:checked ~ label {
    background-color: #e4a4aa;
    border: 1px solid #e4a4aa;
}
